'use client';

const React = require('react');
const ReactDOM = require('react-dom');

const AxeCore: React.FC = () => {
	if (typeof window !== 'undefined') {
		const axe = require('@axe-core/react');
		axe(React, ReactDOM, 1000);
	}
	return null;
};

export default AxeCore;
